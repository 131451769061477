import React from 'react';
import Card from './card';
import Button from './button';
import moderationLogo from '../assets/moderation.png';
import musicLogo from '../assets/music.png';
import jokesLogo from '../assets/jokes.png';
import triviaLogo from '../assets/trivia.png';
import translationLogo from '../assets/translation.png';

const Body = () => {
    return (
        <>
        <div className='flex items-center justify-center p-6 grow gap-6 '>
            <Card img={moderationLogo} title='🛡️ Moderation' description='In the Moderation Commands section, you can efficiently manage user privileges to maintain order within your server. Here, you can grant or revoke access to specific members, ensuring they have the appropriate permissions for their roles. Additionally, you have the ability to assign or remove admin privileges, empowering trusted users to help with server management. These tools are essential for creating a safe, well-organized, and harmonious community.' />
            <Card img={musicLogo} title='🎵 Music' description='In the Music Commands section, you can effortlessly bring your server to life with your favorite tunes. Simply type the song name, and our bot will play it for you, kickstarting the party. Enjoy an all-in-one control panel that allows you to manage everything from pausing, stopping, and skipping tracks to adjusting volume and queuing songs. The interactive music player interface makes it easy to control your music experience without the hassle of multiple commands.' />
            <Card img={jokesLogo} title='😜 Jokes' description="Welcome to the Jokes Commands section! Here, you can lighten the mood with a selection of jokes tailored to your preference. Whether you're in the mood for safe and family-friendly jokes or something a bit more daring, our bot is ready to entertain. Simply specify your choice—safe or NSFW—and the number of jokes you'd like to hear. Let the laughter begin!" />
        </div>
        <div className='flex items-center justify-center p-6 grow gap-6 '>
            <Card img={triviaLogo} title='🤔 Trivia' description='Coming Soon !' />
            <Card img={translationLogo} title='🔡 Translation' description='Coming Soon !' />
        </div>
        <Button >Add to discord</Button>
        </>
    );
};

export default Body;