import React from 'react';
import Logo from '../assets/logo.png'
const Header = () => {
    return (
        <div className='flex items-center justify-center p-6 gap-6 border-b border-gray-600'>
            <img src={Logo} alt='Bazelator Logo' className='w-14 h-14 rounded-full' />
            <h1 className='text-6xl tracking-widest font-bold italic uppercase text-white'>Bazelator</h1>
        </div>
    );
};

export default Header;