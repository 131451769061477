import React from 'react';
import Header from './components/header';
import Body from './components/body';

const LandingPage = () => {
    return (
        <div className='flex flex-col bg-background'>
            <Header /> 
            <Body />
        </div>
    );
};

export default LandingPage;