import React from 'react';
const Card = ({img,title,description}) => {
    return (
        <div className='flex flex-col w-[500px] h-[700px] bg-card border border-gray-600 rounded-xl p-4'>
            <img src={img} alt={title} className='w-full h-auto rounded-xl bg-cover bg-center border border-gray-600 mb-2' />
            <h2 className='text-2xl font-bold text-white text-center mb-2'>{title}</h2>
            <div className='flex flex-col justify-between items-center overflow-auto'>
                <p className='text-white text-justify'>{description}</p>
            </div>

        </div>
    );
};

export default Card;